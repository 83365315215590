// extracted by mini-css-extract-plugin
export var header = "style-module--header--EWnhT";
export var header_images_wrap = "style-module--header_images_wrap--q6PZT";
export var image = "style-module--image--bFHwK";
export var avatar = "style-module--avatar--DCMur";
export var title = "style-module--title--fL0G-";
export var promo = "style-module--promo--YrIGC";
export var promoContent = "style-module--promo-content--KRzQI";
export var mentor = "style-module--mentor--V3STI";
export var roadmaps = "style-module--roadmaps--EIHsj";
export var wrapper = "style-module--wrapper--UyTeh";
export var item = "style-module--item--1YUQf";
export var opacity = "style-module--opacity--X-+LH";
export var digests = "style-module--digests--D8Jlz";
export var answers = "style-module--answers--WknW0";
export var simulator = "style-module--simulator--Umr+N";